/**
 * 暗号化
 * @param {string} text
 * @param {string} passPhrase
 * @returns {boolean|{cipher: string, cipherKey: string}}
 */
function xorEncryption(text, passPhrase) {
  try {
    const asciiBinList = convertAsciiBinary(text)
    const passBinList = convertAsciiBinary(passPhrase)
    return makeCipher(asciiBinList, passBinList)
  } catch (e) {
    console.log(e)
    return ''
  }
}

/**
 * 復号化
 * @param {string} cipher
 * @param {string} cipherKey
 * @param {string} passPhrase
 * @returns {string|boolean}
 */
function xorDecryption(cipher, cipherKey) {
  return breakCipher(
    convertAsciiBinary(base64ToString(cipher)),
    convertAsciiBinary(cipherKey),
  )
}

/**
 * 文字列を2進数asciiコード配列に変換
 *
 * @param {string} text
 * @return {Array} asciiBinList
 */
function convertAsciiBinary(text) {
  let asciiBinList = []
  const charCount = text.length
  if (charCount === 0) {
    throw 'VernamCipher: text is empty.'
  }

  for (let i = 0; i < charCount; i++) {
    asciiBinList.push(text.charCodeAt(i).toString(2))
  }
  return asciiBinList
}

/**
 * 暗号生成
 * @param {Array} asciiBinList
 * @param {Array} passBinList
 * @param {string} passPhrase
 * @return {Object}
 */
function makeCipher(asciiBinList, passBinList) {
  if (asciiBinList.length !== passBinList.length) {
    throw 'VernamCipher - encrypt: The length is different.'
  }

  // xor
  let cipher = ''
  for (const index in asciiBinList) {
    cipher += String.fromCharCode(parseInt(asciiBinList[index], 2) ^ parseInt(passBinList[index], 2))
  }

  return stringToBase64(cipher)
}

/**
 * 復号
 * @param {Array} cipherBin
 * @param {Array} cipherKeyBin
 * @returns
 */
function breakCipher(cipher, cipherKey) {
  if (cipher.length !== cipherKey.length) {
    throw 'VernamCipher - decrypt: The length is different.'
  }

  // xor
  let decryptText = ''
  for (const index in cipher) {
    decryptText += String.fromCharCode(parseInt(cipher[index], 2) ^ parseInt(cipherKey[index], 2))
  }
  return decryptText
}

/**
 * string→base64変換
 * @param {string} str
 * @returns base64変換後の文字列
 */
function stringToBase64(str) {
  return btoa(unescape(encodeURIComponent(str))).replace(/\//g, '_').replace(/\+/g, '-');
}

/**
 * base64→string変換
 * @param {string} str
 * @returns string変換後の文字列
 */
function base64ToString(str) {
  return decodeURIComponent(escape(atob(str.replace(/_/g, '/').replace(/-/g, '+'))));
}

module.exports = {
  xorEncryption,
  xorDecryption,
}
