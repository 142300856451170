import Dexie from 'dexie'
import { getMemberCode } from '@/utils/common.js'

const memberCode = getMemberCode()
export const memberCodeDb = new Dexie(memberCode)
memberCodeDb.version(1).stores({
  'transactions': `++id, member_code, [genesis_ledger_id+member_code], [from+to+created_at], amount`,
  'transfer_history': `++id, genesis_ledger_id, member_code, updated_at`,
})
memberCodeDb.version(3).stores({
  'member_survey_answers': `++id, survey_id, index, cipher, decryption_key, answer_flg, created, modified`,
})
