import { sendMultipleCipher } from '@/api/common.js';
import request from '@/utils/request'

export function sendMoney(data) {
  return sendMultipleCipher(
    '/api/transfer/sendMoney',
    data,
  )
}

export function uploadIpfs(data) {
  return request({
    url: '/api/transfer/uploadIpfs',
    method: 'post',
    data
  })
}
