<template>
  <n-spin description='回答送信中' :show="isProcessing()">
    <!-- Page Content -->
    <h2 class="mt-4 mb-3">{{ surveyDetail.survey_name }}</h2>
    <p class="mx-3 text-left">{{ surveyDetail.explain_text }}</p>
    <div class="container text-left mw-42" id="container">
      <div class="col-lg-12 mb-12">
        <div v-for="question, id in surveyDetail.survey_questions" :key="question" class="form-group p-3 mb-3 question-box" :data-question-id="id" :data-limit-answer-num="question.limit_answer_num">
          <label>
            <span v-html="question.title"></span>
            <span v-if="question.required_flg == 1" class="text-danger font-weight-bold" role="alert">※</span>
          </label>
          <div class="row" :class="{'justify-content-end': Object.keys(question.survey_answers).length < 5, 'pl-3': Object.keys(question.survey_answers).length > 6, 'mw-450 mx-auto': question.answer_type == CONST.SURVEY.ANSWER_TYPE.SINGLE_CHOICE && singleChoiceLabels[question.id].displayRange}">
            <template v-if="question.answer_type == CONST.SURVEY.ANSWER_TYPE.SINGLE_CHOICE && singleChoiceLabels[question.id].displayRange">
              <div class="mx-0 px-0 w-100 row">
                <div class="col-5 text-left small px-0">{{ singleChoiceLabels[question.id].minusWord }} ←</div>
                <div class="col-2"></div>
                <div class="col-5 text-right small px-0">→ {{ singleChoiceLabels[question.id].plusWord }}</div>
              </div>
              <div class="mx-0 px-0 w-100 row">
                <div v-for="answer in question.survey_answers" :key="answer" class="survey_questions col text-center" name="question_item">
                  <div  class="custom-control custom-radio col">
                    <input v-model="answerInput[question.id]" type="radio" :id="answer.id" :name="'answer_' + question.id" :data-question="question.id" class="custom-control-input" :value="answer.id">
                    <label class="custom-control-label" :for="answer.id">{{ singleChoiceLabels[question.id].labels[answer.id] }}</label>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div v-for="answer in question.survey_answers" :key="answer" class="survey_questions" name="question_item" :class="{'col': (question.answer_type == CONST.SURVEY.ANSWER_TYPE.ANY_ADDITIONAL_COMMENTS && question.activity_type == CONST.SURVEY.ACTIVITY_TYPE.RANGE), 'col-sm-3 mw-150': question.answer_type == CONST.SURVEY.ANSWER_TYPE.MULTIPLE_COMMENTS, 'col-auto': question.answer_type == CONST.SURVEY.ANSWER_TYPE.SINGLE_CHOICE} ">
                <!-- SURVEY.ANSWER_TYPE.MULTIPLE_CHOICE -->
                <div v-if="question.answer_type == CONST.SURVEY.ANSWER_TYPE.MULTIPLE_CHOICE" class="custom-control custom-checkbox mr-4">
                  <input v-model="answerInput[question.id]" type="checkbox" :id="answer.id" :name="'answer_' + question.id" :data-question="question.id" class="custom-control-input" :value="answer.id" @change="onChangeCheckbox">
                  <label class="custom-control-label" :for="answer.id" >{{ answer.title }}</label>
                </div>
                <!-- SURVEY.ANSWER_TYPE.SINGLE_CHOICE -->
                <div v-else-if="question.answer_type == CONST.SURVEY.ANSWER_TYPE.SINGLE_CHOICE && !singleChoiceLabels[question.id].displayRange" class="custom-control custom-radio col">
                  <input v-model="answerInput[question.id]" type="radio" :id="answer.id" :name="'answer_' + question.id" :data-question="question.id" class="custom-control-input" :value="answer.id">
                  <label class="custom-control-label" :for="answer.id">{{ singleChoiceLabels[question.id].labels[answer.id] }}</label>
                </div>
                <!-- SURVEY.ANSWER_TYPE.ANY_ADDITIONAL_COMMENTS -->
                <div v-else-if="question.answer_type == CONST.SURVEY.ANSWER_TYPE.ANY_ADDITIONAL_COMMENTS && question.activity_type != CONST.SURVEY.ACTIVITY_TYPE.RANGE" class="form-group row">
                  <input v-model="answerInput[question.id]" type="text" class="form-control col" :id="answer.id" :name="'answer_' + question.id" :data-question="question.id">
                  <div class="col-auto d-flex align-items-center">{{ answer.title }}</div>
                </div>
                <!-- SURVEY.ANSWER_TYPE.MULTIPLE_COMMENTS -->
                <div v-else-if="question.answer_type == CONST.SURVEY.ANSWER_TYPE.MULTIPLE_COMMENTS" class="form-group row" :data-a="qidx">
                  <input v-model="answerInput[question.id][answer.sort_order - 1]" type="text" class="form-control col" :id="answer.id" :name="'answer_' + question.id" :data-question="question.id">
                  <div class="d-flex align-items-center col-auto">{{ answer.title }}</div>
                </div>
                <!-- SURVEY.ACTIVITY_TYPE.RANGE -->
                <div v-else-if="question.answer_type == CONST.SURVEY.ANSWER_TYPE.ANY_ADDITIONAL_COMMENTS && question.activity_type == CONST.SURVEY.ACTIVITY_TYPE.RANGE" class="form-group">
                  <div class="row pl-3">
                    <div class="col">
                      <div class="row small">
                        <div class="col-3 text-left p-0">最大の不幸</div>
                        <div class="col text-center pl-2 pr-2">←</div>
                        <div class="col-3 text-center p-0">どちらともいえない</div>
                        <div class="col text-center pl-2 pr-2">→</div>
                        <div class="col-3 text-right p-0">最高の幸福</div>
                      </div>
                      <div class="row">
                        <input v-model="answerInput[question.id]" type="range" class="custom-range" :id="answer.id" :name="'answer_' + question.id" :data-question="question.id" :ref="setInputRef" min="-100" max="100"/>
                      </div>
                      <div class="row small">
                        <div class="col text-left p-0">-100</div>
                        <div class="col text-center p-0">0</div>
                        <div class="col text-right p-0">100</div>
                      </div>
                    </div>
                    <div class="col-2 d-flex align-items-center">
                      {{ answerInput[question.id] }} %
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div v-if="viewSendButton">
          <ul class="nav mt-3">
            <li><button type="button" class="btn_next btn btn-primary" id="send" :disabled="isProcessing()" @click="sendProcess">送信</button></li>
          </ul>
          <ul class="nav mt-3">
            <li><button type="button" class="btn_next btn btn-secondary" @click="backToPreviousPage">戻る</button></li>
          </ul>
        </div>
      </div>
    </div>
  </n-spin>
</template>
<script>
import { Mixin } from '@/mixin.js'
import { memberCodeDb } from '@/memberCodeDb'
import { isMnemonic, checkSurveyAnswer } from '@/utils/validate.js'
import { createHashSha256 } from '@/utils/cipher.js'
import { apiError } from '@/api/common.js'
import { config, CONST } from '@/constants.js'
import { sleep, getMemberCode, getRequestSecretKey, getUTCTimestamp } from '@/utils/common.js'
import { getSurveySecretKey, getSurveyDetail, getUserAnswer } from '@/api/survey.js'
import { getPersonalSecretKey } from '@/utils/secret-key.js'
import { getAddressByMnemonicAndIndex, getRandomAddressByMnemonic, makeRequestByAes } from '@/utils/make-request-data.js'
import { uploadIpfs } from '@/api/transfer.js'
import { sendAnswer, sendResearchDays, updateActivateFlg } from '@/api/survey.js'
import { xorEncryption } from '@/xorCipher.js'
import vernamCipher from 'vernam_cipher_on_node/vernam_cipher'
import { getActivateFlg } from '@/api/member.js';

export default {
  mixins: [Mixin],
  watch: {
    answerInput: {
      deep: true,
      async handler(e) {
        const nextSurveyQuestionIds = {}
        for (const key in this.surveyDetail.survey_questions) {
          const question = this.surveyDetail.survey_questions[key]
          for (const key2 in question.survey_answers) {
            const answer = question.survey_answers[key2]
            if (answer.next_survey_question_id) {
              if (!nextSurveyQuestionIds[question.id]) nextSurveyQuestionIds[question.id] = {}
              nextSurveyQuestionIds[question.id][answer.id] = answer.next_survey_question_id.split(',')
            }
          }
        }
        this.nextSurveyQuestionIds = nextSurveyQuestionIds
        await this.slideProcess()
        for (const i in e) {
          if (Object.keys(nextSurveyQuestionIds).indexOf(i) !== -1) {
            if (e[i] && Object.keys(nextSurveyQuestionIds[i]).indexOf(e[i].toString()) !== -1) {
              nextSurveyQuestionIds[i][e[i]].forEach(qid => {
                document.querySelector('[data-question="' + qid + '"]').closest('.question-box').classList.remove('d-none')
              })
            }
          }
        }
      }
    }
  },
  data() {
    return {
      CONST: CONST,
      surveyId: null,
      memberCode: null,
      requestSecretKey: null,
      surveySecretKey: null,
      isRefresh: false,
      surveyDetail: {},
      processing: false,
      alreadyAnsweredFlg: false,
      viewSendButton: false,
      rangeInput: 0,
      singleChoiceLabels: {},
      answerInput: {},
    }
  },
  async created() {
    this.surveyId = this.$route.params.id
    if (!this.surveyId) {
      alert('アンケートIDが存在しません。ログイン画面に戻ります')
      this.logout()
      return
    }
    this.memberCode = getMemberCode()
    this.isRefresh = true

    const activate_flg = await getActivateFlg(this.memberCode)

    switch(activate_flg.value) {
      case CONST.MEMBER.ACTIVATE_FLG.RESEARCH_SUBJECT:
        if (this.surveyId != '7') {
          this.$router.push({name: 'RegisterResearch'})
        }
        break
      case CONST.MEMBER.ACTIVATE_FLG.PRODUCTION_AGREE:
        if (this.surveyId != '8') {
          this.$router.push({name: 'Explain'})
        }
        break
      case CONST.MEMBER.ACTIVATE_FLG.PRODUCTION_COMPLETED:
        this.$router.push({name: 'ProductionComplete'})
        break
      default:
        this.$router.push({name: 'LoginPage'})
        break
    }

    await this.getSurveySecretKey()

    if (this.alreadyAnsweredFlg || this.surveySecretKey) {
      await this.view()
    } else {
      const referrer = sessionStorage.getItem('referrer_path')
      if (referrer) {
        alert('投票用の鍵が存在しません。もう一度前の画面からやり直してください。')
        this.$router.replace({ path: referrer })
      } else {
        alert('投票用の鍵が存在しません。ログイン画面に戻ります。')
        this.logout()
      }
    }
    this.isRefresh = false
  },
  methods: {
    async slideProcess() {
      await sleep(10)
      for (const questionId in this.nextSurveyQuestionIds) {
        for (const aid in this.nextSurveyQuestionIds[questionId]) {
          const qid = this.nextSurveyQuestionIds[questionId][aid]
          document.querySelector('[data-question-id="' + qid + '"]').closest('.question-box').classList.add('ml-3', 'd-none')
        }
      }
    },
    startProcessing() {
      this.processing = true
    },
    endProcessing() {
      this.processing = false
    },
    isProcessing() {
      return this.processing
    },
    /**
     * 投票用秘密鍵を生成
     */
    async getSurveySecretKey() {
      this.memberCode = await getMemberCode()
      // リクエスト秘密鍵
      this.requestSecretKey = getRequestSecretKey()
      if (!this.requestSecretKey) {
        alert('ログイン情報が見つかりません。ログイン画面に戻ります。')
        this.logout()
        return
      }
      const data = makeRequestByAes(this.memberCode, this.requestSecretKey, {
        member_code: this.memberCode,
        survey_id: this.surveyId,
      })

      // 投票用秘密鍵を取得
      const surveySecretKey = await getSurveySecretKey(data).catch(e => {
        if (
          e &&
          e.response &&
          e.response.data &&
          e.response.data.code === config.ERRCODE_ALREADY_ANSWERED
        ) {
          this.alreadyAnsweredFlg = true
          this.surveySecretKey = this.getSurveySecretKeyFromLocal()
        } else {
          apiError(e)
        }
      })

      if (this.alreadyAnsweredFlg || !surveySecretKey || !isMnemonic(surveySecretKey)) {
        return
      }
      this.surveySecretKey = surveySecretKey

      // パーソナル秘密鍵取得
      const personalSecretKey = await getPersonalSecretKey(this.memberCode, this.requestSecretKey)
      if (!personalSecretKey) {
        // alert('You are not logged in!')
        alert('ログイン情報が見つかりません。ログイン画面に戻ります。')
        return
      }

      // 乱数を使用してパーソナル秘密鍵からアカウントアドレスを取得
      const addressInfo = getRandomAddressByMnemonic(personalSecretKey)
      const address = addressInfo.address
      const index = addressInfo.random

      // アカウントアドレスをパスフレーズにしてバーナム暗号化
      const encryptedPersonalSecretKey = vernamCipher.encryption(this.surveySecretKey, address)
      const cipher = encryptedPersonalSecretKey.cipher
      const decryption_key = encryptedPersonalSecretKey.cipherKey

      // 既に投票用秘密鍵情報がある場合は更新
      const existData = await this.getMemberSurveyAnswersData()
      // 保存データ作成
      const param = {
        survey_id: this.surveyId,
        index,
        cipher,
        decryption_key,
        answer_flg: false,
        created: getUTCTimestamp(),
        modified: getUTCTimestamp(),
      }
      // 更新の場合はIDを追加
      if (existData && existData.id) {
        param.id = existData.id
      }
      // IndexedDBに保存
      await memberCodeDb.member_survey_answers.put(param)
    },
    /**
     * 世論調査設問情報を取得
     */
    async getDetailData() {
      // indexedDBからユーザの投票用秘密鍵の情報を取得
      const userInfo = await this.getMemberSurveyAnswersData()
      if (!userInfo) {
        // alert('survey secret key is not found!')
        alert('ログイン情報が見つかりません。ログイン画面に戻ります。')
      }

      // 非公開鍵暗号通信のデータ作成
      const sendData = makeRequestByAes(this.memberCode, this.requestSecretKey, {
        user_info: userInfo,
        member_code: this.memberCode,
      })

      const detailApiResult = await getSurveyDetail(sendData).catch(apiError)
      if (!detailApiResult.value) {
        return false
      }
      return detailApiResult.value
    },
    /**
     * 画面表示
     */
    async view() {
      // 既に回答済みであればTOPに戻るGapファンド用暫定対応
      if (this.alreadyAnsweredFlg) {
        alert('既に回答済みです。完了画面に移動します。')
        if (this.surveyId == '7') {
          const res = await updateActivateFlg(makeRequestByAes(this.memberCode, this.requestSecretKey, {
            member_code: this.memberCode,
            activate_flg: CONST.MEMBER.ACTIVATE_FLG.PRODUCTION_AGREE,
          })).catch(new Function())
          if (!res || !res.value) {
            alert('エラーが発生しました。ログイン画面に戻ります')
            this.logout()
            return
          }
          this.$router.push({name: 'AnswerComplete'})
        } else if (this.surveyId == '8') {
          const res = await updateActivateFlg(makeRequestByAes(this.memberCode, this.requestSecretKey, {
            member_code: this.memberCode,
            activate_flg: CONST.MEMBER.ACTIVATE_FLG.PRODUCTION_COMPLETED,
          })).catch(new Function())
          if (!res || !res.value) {
            alert('エラーが発生しました。ログイン画面に戻ります')
            this.logout()
            return
          }
          this.$router.replace({name: 'ProductionAnswerComplete'})
        } else {
          alert('存在しないアンケートです。ログイン画面に戻ります。')
          this.logout()
        }
        return
      }
      // 設問情報を取得
      this.surveyDetail = await this.getDetailData()
      console.log(this.surveyDetail)
      if (!this.surveyDetail) throw new Error('Survey data could not read.')

      // ラジオボタン表示用データ作成
      for (const key in this.surveyDetail.survey_questions) {
        const question = this.surveyDetail.survey_questions[key];
        if (question.answer_type == CONST.SURVEY.ANSWER_TYPE.SINGLE_CHOICE) {
          let minusWord = null;
          let plusWord = null;
          let labels = {};

          for (const key in question.survey_answers) {
            const answer = question.survey_answers[key];
            const title = answer.title;

            let label = title;

            if (/.*<\d/.test(title)) {
              const index = title.indexOf('<');
              minusWord = title.substring(0, index);
              label = title.substring(index + 1, title.length);
            }
            if (/\d>.*/.test(title)) {
              const index = title.indexOf('>');
              plusWord = title.substring(index + 1, title.length);
              label = title.substring(0, index);
            }

            labels[answer.id] = label;
          }

          this.singleChoiceLabels[question.id] = {
            displayRange: minusWord != null && plusWord != null,
            minusWord: minusWord,
            plusWord: plusWord,
            labels: labels,
          }
        }
      }

      // ユーザーの回答を初期化
      for (const key in this.surveyDetail.survey_questions) {
        const question = this.surveyDetail.survey_questions[key]
        this.answerInput[question.id] = null
        this.answerInput[question.id] = (() => {
          if (
            question.answer_type == CONST.SURVEY.ANSWER_TYPE.SINGLE_CHOICE &&
            question.activity_type == CONST.SURVEY.ACTIVITY_TYPE.RANGE &&
            question.survey_answers[0]
          ) {
            return this.answerInput[question.id] || question.surveyAnswers[0].id
          }
          if (
            question.answer_type == CONST.SURVEY.ANSWER_TYPE.ANY_ADDITIONAL_COMMENTS &&
            question.activity_type == CONST.SURVEY.ACTIVITY_TYPE.RANGE
          ) {
            return this.answerInput[question.id] || '0'
          }
          switch (question.answer_type) {
            case CONST.SURVEY.ANSWER_TYPE.ANY_ADDITIONAL_COMMENTS:
              return this.answerInput[question.id] || ''
            case CONST.SURVEY.ANSWER_TYPE.SINGLE_CHOICE:
              return this.answerInput[question.id] || null
            case CONST.SURVEY.ANSWER_TYPE.MULTIPLE_CHOICE:
            case CONST.SURVEY.ANSWER_TYPE.MULTIPLE_COMMENTS:
              return this.answerInput[question.id] || []
            default:
              return this.answerInput[question.id] || null
          }
        })()
      }

      // 既に回答している場合、ユーザの回答を取得
      const answers = await this.getMyAnswer().catch(e => {
        throw new Error(e);
      });

      if (!answers) {
        this.viewSendButton = true
        return
      }

      for (const key in answers) {
        let answer = answers[key]
        if (typeof answer == 'string') answer = [answer]
        for (const a of answer) {
          const obj = document.querySelector('input[name="answer_' + key + '"][value="' + a + '"]')
          switch (obj.getAttribute('type').toLowerCase()) {
            case 'text':
              obj.value = a
              break
            case 'checkbox':
            case 'radio':
              obj.checked = true
              break
          }
        }
      }
      document.querySelectorAll('input[name^="answer_"]').forEach(obj => {
        obj.disabled = true
      })
    },
    /**
     * ユーザの投票情報を取得する
     */
    async getMyAnswer() {
      // 投票用秘密鍵をローカルから取得
      if (!this.surveySecretKey) {
        this.surveySecretKey = await this.getSurveySecretKeyFromLocal()
      }
      const info = await this.getMemberSurveyAnswersData()
      // 未回答の場合はfalseを返す
      if (!info.answer_flg) return false

      // パーソナル秘密鍵取得
      const personalSecretKey = await getPersonalSecretKey(this.memberCode, this.requestSecretKey)
      if (!personalSecretKey) {
        // alert('You are not logged in!')
        alert('ログイン情報が見つかりません。ログイン画面に戻ります。')
        return
      }
      const userCode = xorEncryption(
        getAddressByMnemonicAndIndex(this.surveySecretKey, this.surveyId),
        getAddressByMnemonicAndIndex(personalSecretKey, this.surveyId)
      )
      const myAnswer = await getUserAnswer({
        survey_id: this.surveyId,
        user_code: userCode,
      }).catch(apiError)

      if (!myAnswer.value) return false

      return myAnswer.value
    },
    /**
     * 投票処理
     */
    async sendProcess() {
      // if (!confirm('Are you sure you want to send this message?')) return
      if (!confirm('この内容でアンケートを送信してよろしいですか？')) return
      this.startProcessing()
      try {
        const names = []
        const answers = {}
        document.querySelectorAll('[name^=answer_]').forEach(this_obj => {
          const name = this_obj.getAttribute('data-question')
          const value = this_obj.value
          if (names.indexOf(name) < 0) {
            names.push(name)
          }

          var addFlg = false
          switch (this_obj.getAttribute('type').toLowerCase()) {
            case 'text':
            case 'range': 
              if (this_obj.value !== '') addFlg = true
              break
            case 'checkbox':
            case 'radio':
              if (this_obj.checked) addFlg = true
              break
          }
          if (addFlg) {
            if (!answers[name]) {
              answers[name] = value
            } else if (answers[name] && typeof answers[name] == 'object') {
              answers[name].push(value)
            } else {
              answers[name] = [answers[name], value]
            }
          }
        })
        const surveyData = await this.getDetailData()
        if (!surveyData) throw new Error('投票データが見つかりません。画面をリロードしてやり直してください。')
        const questions = surveyData.survey_questions

        // 回答済みか確認
        document.querySelectorAll('[data-question-id]').forEach(i => {
          i.classList.remove('answer-error')
        })

        const errors = checkSurveyAnswer(this.answerInput, questions, this.nextSurveyQuestionIds)
        if (!Object.keys(errors).length) {
          await this.sendResult(answers)
        } else {
          // let errorMessage = ''
          let errorIds = []
          Object.keys(errors).forEach(e => {
            // const num = questions[e].sort_order
            const id = questions[e].id
            if (errors[e] == config.ERROR_TYPE.REQUIRED) {
              // errorMessage += 'Error: ' + e + ". This field is required.\n"
              // errorMessage += '設問' + num + ". この項目は必須項目です。\n"
              errorIds.push(id)
            } else if (errors[e] == config.ERROR_TYPE.INVALID) {
              // errorMessage += '設問' + num + ". 回答の形式を確認してください。\n"
              errorIds.push(id)
            } else if (errors[e] == config.ERROR_TYPE.EXCEED_LIMIT) {
              // errorMessage += '設問' + num + ". 最大選択可能数を超えています。\n"
              errorIds.push(id)
            }
          })
          if (errorIds.length > 0) {
            console.log(errorIds)
            errorIds.forEach(i => {
              document.querySelector('[data-question-id="' + i + '"]').classList.add('answer-error')
            })
            alert('必須項目を全てチェック、または記述していただくことで回答が完了できます。')
          }
        }
      } catch (e) {
        console.log(e.message)
        alert('投票時にエラーが発生しました。')
      } finally {
        this.endProcessing()
      }
    },
    /**
     * 投票情報送信処理
     */
    async sendResult(answers) {

      // 2回目アンケート
      if (this.surveyId == 7 && !this.research.sampleExpireFlg) {
        const sampleDay = localStorage.getItem('sampleDay')
        const collectionDaysJson = localStorage.getItem('collectionDays')
        if (!collectionDaysJson) {
          alert('採取予定日または試料回収希望日時が未入力です。戻って設定し直してください。')
          return
        }
        const collectionDays = JSON.parse(collectionDaysJson)
        if (!sampleDay || !collectionDays) {
          alert('採取予定日または試料回収希望日時が未入力です。戻って設定し直してください。')
          return
        }
        // const day1 = this.chars8ToDate(sampleDay)
        const day1 = sampleDay
        // const day2 = collectionDays.map(d => {
        //   return this.chars12ToDate(d)
        // })
        const day2 = collectionDays
        if (!day1 || !day2.length) {
          alert('採取予定日または試料回収希望日時が不正です。戻って設定し直してください。')
          return
        }
        const sdata = makeRequestByAes(this.memberCode, this.requestSecretKey, {
          member_code: this.memberCode,
          sample_day: day1,
          collection_days: day2,
        })
        const res = await sendResearchDays(sdata).catch(new Function())

        if (!res || !res.value) {
          alert('採取予定日、試料回収希望日時の送信中にエラーが発生しました。')
          return
        }
      }

      // パーソナル秘密鍵取得
      const personalSecretKey = await getPersonalSecretKey(this.memberCode, this.requestSecretKey)
      if (!personalSecretKey) {
        // alert('You are not logged in!')
        alert('ログイン情報が見つかりません。ログイン画面に戻ります。')
        return
      }
      const info = await this.getMemberSurveyAnswersData()
      let address, index
      if (info && info.index) {
        index = info.index
        address = getAddressByMnemonicAndIndex(this.surveySecretKey, index)
      } else {
        const addressInfo = getRandomAddressByMnemonic(this.surveySecretKey)
        index = addressInfo.random
        address = addressInfo.address
      }
      const userCode = xorEncryption(
        getAddressByMnemonicAndIndex(this.surveySecretKey, this.surveyId),
        getAddressByMnemonicAndIndex(personalSecretKey, this.surveyId)
      )
      const answerHash = createHashSha256(userCode + JSON.stringify(answers))
      const finalityTransactionJson = JSON.stringify({
        user_code: userCode,
        survey_id: this.surveyId,
        answer_hash: answerHash,
      })
      const encryptedFinalityTransactionJson = vernamCipher.encryption(
        finalityTransactionJson,
        address,
      )

      const [ipfsHash, decryptionHash] = await Promise.all([
        // バーナム暗号化したファイナリティトランザクションJSONデータをIPFSにアップロード
        this.uploadToIpfs(encryptedFinalityTransactionJson.cipher),
        // バーナム暗号化したファイナリティトランザクションJSONデータの鍵をIPFSにアップロード
        this.uploadToIpfs(encryptedFinalityTransactionJson.cipherKey),
      ]);

      // 非公開鍵暗号通信のデータ作成
      const sendData = makeRequestByAes(this.memberCode, this.requestSecretKey, {
        user_code: userCode,
        survey_secret_key: this.surveySecretKey,
        survey_id: this.surveyId,
        index: index,
        ipfs_hash: ipfsHash,
        decryption_hash: decryptionHash,
        answer: answers,
      })

      // 回答送信
      const response = await sendAnswer(sendData).catch(apiError)
      if (response && response.result) {
        await this.addAnswerFlg()
        if (this.surveyId == '8') {
          // 本番アンケート完了画面に遷移
          this.$router.push({name: 'ProductionAnswerComplete'})
        } else if (this.surveyId == '7') {
          // 2回目アンケート完了画面に遷移
          this.$router.push({name: 'AnswerComplete'})
        } else {
          alert('存在しないアンケートです。ログイン画面に戻ります。')
          this.logout()
          // this.$router.push({name: 'AnswerComplete'})
        }
      }
    },
    /**
     * IPFSにアップロード
     *
     * @param {string} data アップロードする内容
     */
    async uploadToIpfs(data) {
      // ファイナリティトランザクションJSONデータをIPFSにアップロード
      const uploadIpfsHashApiResult = await uploadIpfs({
        value: data,
      }).catch(apiError)
      if (
        !uploadIpfsHashApiResult ||
        !uploadIpfsHashApiResult.value
      ) {
        // alert('Upload to IPFS failed!')
        alert('回答の送信に失敗しました。')
        this.endProcessing()
        return
      }
      // ファイナリティトランザクションJSONのIPFS Hash
      return uploadIpfsHashApiResult.value
    },
    /**
     * checkboxのチェック時イベント
     */
    onChangeCheckbox(event) {
      const questionNode = event.target.closest('[data-question-id]')
      if (!questionNode) return false

      const limitAnswerNum = parseInt(questionNode.getAttribute('data-limit-answer-num'))

      let answerCnt = 0;
      questionNode.querySelectorAll('[name^=answer_]').forEach(o => {
        if (o.checked) answerCnt++
      })

      if (answerCnt > limitAnswerNum) {
        // alert('Please select no more than ' + limitAnswerNum + ' answers to this question')
        alert('この設問には、最大' + limitAnswerNum + '個までチェックできます。')
        event.target.checked = false
      }
    },
    /**
     * 投票用秘密鍵をローカルから取得する
     */
    async getSurveySecretKeyFromLocal() {
      const personalSecretKey = await getPersonalSecretKey(this.memberCode, this.requestSecretKey)
      if (!personalSecretKey) return false
      const info = await this.getMemberSurveyAnswersData()
      const address = getAddressByMnemonicAndIndex(personalSecretKey, info.index)
      this.surveySecretKey = vernamCipher.decryption(info.cipher, info.decryption_key, address)
    },
    /**
     * indexedDBから投票用秘密鍵の情報を取得する
     */
    async getMemberSurveyAnswersData() {
      const res = await memberCodeDb.member_survey_answers.where({
        survey_id: this.surveyId,
      }).toArray()
      if (!res || !res[0]) return false
      return res[0]
    },
    /**
     * 回答フラグをつける
     */
    async addAnswerFlg() {
      const info = await this.getMemberSurveyAnswersData()
      info.answer_flg = true
      // IndexedDBに保存
      await memberCodeDb.member_survey_answers.put(info)
    },
    backToPreviousPage() {
      if (this.surveyId == 7) {
        this.$router.push({name: 'ResearchConfirm'})
      } else if (this.surveyId == 8) {
        this.$router.push({name: 'Explain'})
      } else {
        this.logout()
      }
    }
  },
}
</script>
<style scoped>
div#wallet{
  margin-top: 30px;
}
table{
  width: 40%;
  margin: 0 auto;
}
th{
  background-color: #007db9;
  color: #fff;
}
th, td{
  padding: 5px;
  border: thin solid rgba(0, 0, 0, 0.12);
}
#detail-btn button{
  cursor: pointer;
}
td {
  font-size:small;
}
</style>
<style src="../assets/css/pages/questionnaire.css" scoped></style>
