import { db } from '../db';
import vernamCipher from 'vernam_cipher_on_node/vernam_cipher';

/**
 * パーソナル秘密鍵を取得
 * @param {string} memberCode
 * @param {string} requestSecretKey
 * @returns {string} personalSecretKey
 */
export async function getPersonalSecretKey(memberCode, requestSecretKey) {
  // indexedDBからログイン情報を取得（暗号化されたパーソナル秘密鍵、パーソナル秘密鍵の復号キーの取り出しの為）
  const result = await db.member_private_keys.where({member_code: memberCode}).toArray();
  if (!result.length) {
    return false;
  }
  // 暗号化されたパーソナル秘密鍵
  const cipherPrivateKey = result[0].cipher_private_key;
  // パーソナル秘密鍵の復号キー
  const decryptionKey = result[0].decryption_key;

  // パーソナル秘密鍵を復号
  const personalSecretKey = vernamCipher.decryption(cipherPrivateKey, decryptionKey, requestSecretKey);

  return personalSecretKey;
}
